import Service from '@/helpers/service'

export default {
    get(page, params) {
        return Service().get('characteristic-item-name?page=' + page, {params});
    },
    getByType(inc, type) {
        return Service().get('characteristic-item-name/char-item-name-by-type/'+inc+'/'+type);
    }, 
    add_char_item_name(params) {
        return Service().post('characteristic-item-name/add-char-item-name', params);
    }, 
    update_char_item_name(inc, params) {
        return Service().post('characteristic-item-name/update-char-item-name/'+inc, params);
    },
    delete_char_item_name(cin_code) {
        return Service().post('characteristic-item-name/delete-char-item-name/'+cin_code);
    },
    get_characteristic_value(page, cin_code, params) {
        return Service().get('characteristic-item-name/characteristic-value/'+cin_code+'?page='+page, {params});
    },
    get_approved_characteristic_value(cin_code) {
        return Service().get('characteristic-item-name/approved-characteristic-value/'+cin_code);
    },
    get_used_characteristic_value(cin_code, char_value) {
        return Service().get('characteristic-item-name/used-count/'+cin_code+'/'+char_value);
    },
    add_char_value(params) {
        return Service().post('characteristic-item-name/add-char-value', params);
    }, 
    update_char_value(id, params) {
        return Service().post('characteristic-item-name/update-char-value/'+id, params);
    }, 
    delete_char_value(id) {
        return Service().post('characteristic-item-name/delete-char-value/'+id);
    },
}